<template>
  <div id="app">
    <div class="page-header">
      <el-popover
        :visible-arrow="false"
        popper-class="menu-popover"
        v-model="menuShow"
      >
        <div class="menu-list">
          <span
            :class="[
              'menu-item',
              item.routeName == curRouteName ? 'active-menu' : '',
            ]"
            v-for="item in menuList"
            :key="item.path"
            @click="handleGo(item.path)"
          >
            {{ item.name }}
            <p></p>
          </span>
        </div>

        <span class="menu-btn" slot="reference">
          <img src="../assets/menu@2x.png" alt="" />
        </span>
      </el-popover>

      <span>{{ curTitle }}</span>
    </div>

    <div class="page-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageLayout",
  data() {
    return {
      menuShow: false,
      menuList: [
        {
          path: "/home",
          routeName: "Home",
          name: "首页",
        },
        {
          path: "/download",
          routeName: "Download",
          name: "APP下载",
        },
        {
          path: "/help",
          routeName: "Help",
          name: "帮助中心",
        },
        {
          path: "/appeal",
          routeName: "Appeal",
          name: "账号申诉",
        },
        {
          path: "/contact",
          routeName: "Contact",
          name: "联系我们",
        },
      ],
    };
  },
  computed: {
    curRouteName() {
      if(this.$route.name != null){
        return this.$route.name;
      }else{
        return '首页';
      }
    },
    curTitle() {
      const curMenu = this.menuList.find(
        (item) => item.routeName == this.curRouteName
      );
      if(curMenu != undefined){
        return curMenu.name == "首页" ? "叮咚" : curMenu.name;
      }else{
        return '首页';
      }
    },
  },
  methods: {
    handleGo(path) {
      if (path == this.$route.path) return;
      this.$router.push(path);
      this.menuShow = false;
    },
  },
};
</script>

<style scoped>
.page-header {
  width: 100%;
  height: 0.88rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.34rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  position: relative;
}
.page-header .menu-btn {
  width: 0.39rem;
  height: 100%;
  padding: 0 0.25rem;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}
.menu-btn img {
  width: 0.39rem;
}

.menu-list {
  width: 2.53rem;
  height: 5.76rem;
  padding: 0.39rem 0.34rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../assets/menu-bg@2x.png");
  background-size: 100%;
}
.menu-list .menu-item {
  width: 1.85rem;
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.27rem;
  box-sizing: border-box;
  border-radius: 0.12rem;
  color: #666;
  font-size: 0.32rem;
  text-align: justify;
}
.menu-item p {
  display: inline-block;
  width: 100%;
}
.menu-list .menu-item.active-menu {
  background-color: #006fff;
  border-radius: 0.12rem;
  color: #fff;
}
.page-content {
  height: calc(100vh - 0.88rem);
  background-color: #f6f6f6;
  overflow: scroll;
}
</style>

<style>
.menu-popover {
  box-shadow: none;
  border: none;
  padding: 0;
  min-width: 0;
  margin-top: 0.15rem !important;
  background: transparent;
  left: 0.2rem !important;
}
</style>
