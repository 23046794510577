<template>
  <PageLayout />
</template>

<script>
import PageLayout from "@/components/PageLayout.vue";

export default {
  name: "App",
  components: {
    PageLayout,
  },
};
</script>

<style>
html {
  font-size: calc(100vw / 15 * 2);
}
body {
  margin: 0;
  color: #0d0e15;
}
input {
  border: none;
  background: none;
}
input:focus {
  outline: none;
}
</style>
