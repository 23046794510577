import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "redirect",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "parameter" */ "@/views/Home.vue")
  },
  {
    path: "/download",
    name: "Download",
    component: () =>
      import(/* webpackChunkName: "parameter" */ "@/views/Download.vue")
  },
  {
    path: "/help",
    name: "Help",
    component: () =>
      import(/* webpackChunkName: "parameter" */ "@/views/Help.vue")
  },
  {
    path: "/appeal",
    name: "Appeal",
    component: () =>
      import(/* webpackChunkName: "parameter" */ "@/views/Appeal.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "parameter" */ "@/views/Contact.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router
